<template>
  <section class="py-16 text-center text-white lg:py-32 bg-gradient-black-01 lg:text-left">
    <Container class="flex flex-col items-center lg:grid lg:grid-cols-2">
      <div class="max-w-sm px-6 py-8 md:max-w-lg lg:max-w-none lg:p-0">
        <h2 class="font-medium text-5xl tracking-[-1px] leading-tight whitespace-pre-line">
          {{ title }}
        </h2>
        <div v-if="copy"
             class="mt-8 font-medium lg:text-lg"
             v-html="copy"
        />
      </div>

      <SectionNewsletterForm v-if="content"
                             :submit-label="content.submitLabel"
                             :contact-list-id="contactListId"
                             :input-label="content.inputLabel"
                             :input-placeholder="content.inputPlaceholder"
                             :error-message="content.errorMessage"
                             :success-message="content.successMessage"
                             :consent-label="privacyLabel"
      />
    </Container>
  </section>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useAsyncData, queryContent } from '#imports'
import { ContactListId } from '~/constants/email'

/**
 * Newsletter section
 *
 * Holds the title and html copy + the newsletter form.
 */
interface SectionNewsletterProps {
  contactListId: number
  title: string
  copy?: string
}

const props = defineProps<SectionNewsletterProps>()

// Fetch locations from globals
const { data } = await useAsyncData('newsletter', () => queryContent('globals', 'newsletter').findOne())

const content = computed(() => {
  if (data && data.value) {
    return data.value
  }

  return false
})

const privacyLabel = computed(() => {
  if (!content.value) {
    return ''
  }

  if (props.contactListId === ContactListId.SALES) {
    return content.value.privacyLabelSales
  }

  if (props.contactListId === ContactListId.MEDIA) {
    return content.value.privacyLabelMedia
  }

  if (props.contactListId === ContactListId.CAREERS) {
    return content.value.privacyLabelCareers
  }

  if (props.contactListId === ContactListId.TALENT_CAMP) {
    return content.value.privacyLabelTalentCamp
  }

  return content.value.privacyLabel
})
</script>
